export default () => {
    const yMap = document.querySelector('.map');

    if (yMap) {
        var myMap;
        ymaps.ready(init);
        function init() {
            var myMap = new ymaps.Map('ya-map', {
                center: [52.021903, 47.789444],
                zoom: 17,
            });
            myMap.controls.remove('searchControl');
            myMap.behaviors.disable('scrollZoom');

            myMap.geoObjects.add(
                new ymaps.Placemark(
                    [52.021903, 47.789444],
                    {
                        // balloonContentHeader: "Компания «Love for Life»",
                        // balloonContentBody: "г. Балаково, ул. Шевченко, 23а)",
                        // balloonContentFooter: "тел.: +7 (937) 221-34-43 e-mail: mail@liveforlife.ru"
                    },
                    {
                        iconLayout: 'default#image',
                        // Своё изображение иконки метки.
                        iconImageHref:
                            '/wp-content/themes/polytehnika/assets/img/pin.png',
                        // Размеры метки.
                        iconImageSize: [100, 117],
                        // Смещение левого верхнего угла иконки относительно
                        // её "ножки" (точки привязки).
                        iconImageOffset: [-60, -71],
                    }
                )
            );
        }
    }
};
