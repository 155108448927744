import Swiper from 'swiper/swiper-bundle.min';

export default () => {
    if (window.innerWidth < 480) {
    } else {
    }

    const swiper = new Swiper('#slider', {
        centeredSlides: true,
        navigation: {
            nextEl: '.slide-btn-next',
            prevEl: '.slide-btn-prev',
        },
    });
};
