import $ from 'jquery';
import Inputmask from 'inputmask';

export default () => {
    $(function () {
        // alert();

        if ($('.search-close-btn').length > 0) {
            $('.search-close-btn').on('click', function () {
                $('.search-wrap').hide();
                $('.search-result').empty();
                $('.search_form input[name="s"]').val('');
            });
        }
        if ($('.h-search-wrap').length > 0) {
            $('.h-search-wrap').on('click', function () {
                $('.search-wrap').show();
            });
        }

        $('.search-form input[name="s"]').on('keyup', function () {
            var search = $('.search-form input[name="s"]').val();
            if (search.length < 4) {
                return false;
            }
            var data = {
                s: search,
                action: 'search_ajax',
                nonce: search_form.nonce,
            };
            $.ajax({
                url: search_form.ajaxurl,
                data: data,
                type: 'POST',
                dataType: 'json',
                success: function (data) {
                    if (data.out) {
                        $('.ajaxSearchResult').html(data.out);
                    }
                },
            });
            console.log(search);
        });
    });

    function attention() {
        if (localStorage.getItem('attention') !== null) {
            // document.getElementById('attention').remove();
        } else {
            var newDiv = document.createElement('div');
            newDiv.id = 'attention';
            newDiv.className = 'attention';
            newDiv.innerHTML =
                '<span>Мы использует cookie, чтобы сделать пользование сайтом проще. <a href="/privacy">Узнайте больше про использование cookie.</a></span><div class="attention__btn">Хорошо</div>';
            //etc.
            document.body.appendChild(newDiv);
        }

        const attentionClick = document.querySelector('.attention__btn');
        if (attentionClick !== null) {
            attentionClick.onclick = () => {
                localStorage.setItem('attention', '1');
                document.getElementById('attention').remove();
            };
        }
    }

    attention();

    var sttElem = document.querySelector('.gotop');
    var screanHeight = window.innerHeight;

    var sttScroll = function sttScroll() {
        document.addEventListener('scroll', function (e) {
            if (screanHeight <= window.scrollY) {
                sttElem.classList.add('show');
            } else if (e.target.scrollingElement.scrollTop <= screanHeight) {
                sttElem.classList.remove('show');
                sttElem.style.pointerEvents = 'auto';
            }
        });
    };

    var sttClick = function sttClick() {
        sttElem.addEventListener('click', function () {
            var docHeight = window.scrollY;
            var progress = 0;
            var position = docHeight;
            var speed = 5; // When increasing this value. The scrolling speed will increase

            sttElem.style.pointerEvents = 'none';

            var sttAnim = function sttAnim() {
                progress += 1;
                position -= progress * speed;
                window.scrollTo(0, position);

                if (position > 0) {
                    requestAnimationFrame(sttAnim);
                }
            };

            requestAnimationFrame(sttAnim);
        });
    };

    var sttFunc = function sttFunc() {
        sttScroll();
        sttClick();
    };

    sttFunc();

    const tel = new Inputmask('+7 (999) 999-99-99');
    tel.mask(document.querySelectorAll('input[type="tel"]'));
};
